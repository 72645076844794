import React from 'react';
import Table from '../parents/Table.js';
import DomainsModalDNS from './DomainsModalDNS.js';
import DOmainsModalDelete from './DomainsModalDelete.js';
import DomainsModalDisable from './DomainsModalDisable.js';

const DomainsTable = ({ reload }) => {
    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    const url = DJANGO_HOST + 'domain/';

    const headers = [
        { name: "Domain", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true },
        { name: "Open Rate", available: false },
        { name: "Reply Rate", available: false },
        { name: "Bounce Rate", available: false },
        { name: "Spam Complaints", available: false },
        { name: "Status", available: true },
        { name: "Health", available: false }
      ];
  
    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {
        console.log("hello from " + id + "with option " + option);

        if(option === "verify"){
            return(
                <>
                    <DomainsModalDNS/>
                </>
                
            );
        }
    };

    const renderPrefix = (row) => {
        const isChecked = row.active === "Active" ? true : false;

        return (
            <td className="py-3 px-6 flex">
                <DomainsModalDisable isChecked={isChecked}></DomainsModalDisable>
            </td>
        );
    };

    const renderSuffix = (row) => {
        console.log(row);
        return (
            <td className="py-3 px-2 flex">        
                <DomainsModalDNS reload={reload} name={row.name} id={row.id}></DomainsModalDNS>
                <DOmainsModalDelete reload={reload} name={row.name} id={row.id}></DOmainsModalDelete>
            </td>
        );
    };
  
    return (
      <Table
        url={url}
        title={"Domains"}
        headers={headers}
        preffixColumn={null}
        suffixColumn={"Actions"}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
        handlePrefixAction={handlePrefixAction}
        handleSuffixAction={handleSuffixAction}
      />
    );
  };
  
  export default DomainsTable;