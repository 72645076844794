import React from 'react';
import Table from '../parents/Table.js';
import Checkbox from '../parents/Checkbox.js';
import ConnectedIpsModalEdit from './ConnectedIpsModalEdit.js';
import ConnectedIpsModalDelete from './ConnectedIpsModalDelete.js';



const ConnectedIpsIPsTable = ({ reload }) => {

    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    const url = DJANGO_HOST + 'user_relay_server/';

    const headers = [
      { name: "IPS", available: true },
      { name: "Provider", available: true },
      { name: "Projects", available: true },
      { name: "Domains", available: true },
      { name: "Mailboxes", available: true },
      { name: "Sent Emails", available: true },
      { name: "Delivered", available: false },
      { name: "Open Rate", available: false },
      { name: "Reply Rate", available: false },
      { name: "Bounce Rate", available: false },
      { name: "Spam Complaints", available: false },
      { name: "Status", available: true },
      { name: "Health", available: false }
    ];
  
    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
      return (
        <td className="py-3 pl-6 text-left whitespace-nowrap">
          <Checkbox id={row.id}/>
        </td>
      );
    };

    const renderSuffix = (row) => {
      return (
        <td className="py-3 px-2 flex">
          <ConnectedIpsModalEdit name={row.relay_server_name} id={row.id} reload={reload}></ConnectedIpsModalEdit>
          <ConnectedIpsModalDelete name={row.relay_server_name} id={row.id} reload={reload}></ConnectedIpsModalDelete>
        </td>
      );
    };
    
  
    return (
      <Table
        url={url}
        title={"Connnected IPs"}
        headers={headers}
        preffixColumn={null}
        suffixColumn={"Actions"}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
      />
    );
  };
  
  export default ConnectedIpsIPsTable;