import React from "react";

const defaultStyles = {
    Button : {
        cursor: 'pointer',
        padding: '2px 8px',
        margin: '0px 4px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: '#39d9d3',
        color: '#000000',
        fontWeight: 700,
        lineHeight: '20px',
        outline: 'none',
    },
    Label : {
        color : 'red'
    }
};

const defaultLabel = <label style={defaultStyles.Label}>ErrorButton</label>;

const Button = ({ label=defaultLabel, style=defaultStyles.Button, action, rightIcon }) => {
    return (
        <button style={style} onClick={action}>{label} {rightIcon}</button>
    );
};

export default Button;