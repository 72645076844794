import React from 'react';

const defaultStyles = {
  Input: {
    padding: '0px 8px',
    border: '1px solid #505050',
    boxSizing: 'border-box',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    backgroundColor: '#ffffff',
    color: '#000000',
    fontSize: '14px',
    lineHeight: '24px',
    outline: 'none',
  },
};

const Input = ({ placeholder, value, name, onChange }) => {
  return (
    <input name={name} value={value} onChange={onChange} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" placeholder={placeholder} />
  );
};

export default Input;