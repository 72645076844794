import Card from '../parents/Card.js';

const ControlCenterCard = ({label, data, change, status}) => {
    const renderLabel = () => {
        return (
            <label className="font-bold">{label}</label>
        );
    }

    const renderBody = () => {
        return (
            <p>{data}</p>
        );
    }

    const renderIcon = () => {
        return (
            <div className="flex">
                {status == "Good" ? (
                    <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-green-500 mr-1" 
                    fill="currentColor" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                    >
                    <path  
                    d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" 
                    />
                    </svg>
                ) : status == "Bad" ? (
                    <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-red-500 mr-1" 
                    fill="currentColor" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                    >
                    <path 
                    d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" 
                    />
                    </svg>
                ) : (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-500 mr-1"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path
                        fillRule="evenodd"
                        d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                    </svg>
                )}
                <label>{change}</label>
            </div>
        );
    }

    return (
        <Card
            renderLabel={renderLabel}
            renderBody={renderBody}
            renderIcon={renderIcon}
        />
    );

}

export default ControlCenterCard;
