import React from 'react';

const styles = {
  ImageContainer: {
    top: '17px',
    left: '68px',
    width: '54px',
    height: '38px',
    borderRadius: '8px',
    backgroundImage: 'url(./image.png)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};

const defaultProps = {
  image: 'https://assets.api.uizard.io/api/cdn/stream/4dd34ccd-f2ac-4779-b8a2-f148db2e2881.png',
}

const TopLogo = (props) => {
  return (
    <div style={{
      ...styles.ImageContainer,
      backgroundImage: `url(${props.image ?? defaultProps.image})`,
    }} />
  );
};

export default TopLogo;