import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const MailboxesModalDisable = ({ isChecked, name }) => {
    const [errors, setErrors] = useState([]);

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to disable - MAILBOX NAME</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <p className="text-justify">
                    By disabling this mailbox, no emails will be sent from it.
                </p>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        /* Default scenario, two buttons, one for Cancel and one for Accept  */
        const action = () => {
        
        };
        
        return(
            <>
                <Button action={closeModal} style={defaultStyles.CancelButton} label="Close"></Button>
                <Button action={action} style={defaultStyles.AcceptButton} label="Verifiy"></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
        toggle={true}
        isChecked={isChecked}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalDisable;