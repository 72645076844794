import React from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const MailboxesModalGet = () => {
    

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Get Mailbox</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <div class="text-center">
                    <h1 class="text-4xl font-bold text-gray-700">Under Development</h1>
                    <p class="mt-2 text-gray-500">We are working hard to bring you this feature.</p>
                </div>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        const accept = () => {
            console.log("Example");
        };
        
        return(
            <>
                <Button action={closeModal} style={defaultStyles.CancelButton} label="Cancel"></Button>
                <Button action={accept} style={defaultStyles.AcceptButton} label="Yes, I accept"></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <></>
        );
    };

    
    return (
        <Modal
        text={"Get Mailbox"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalGet;