import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const ConnectedIpsModalIpForSales = ({}) => {
    const [errors, setErrors] = useState([]);

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to add an IP for Sales?</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <p className="text-justify">
                    By adding an IP for Sales, you are adding a pool of dedicated IPs that will rotate among your 
                    domains and mailboxes to achieve high deliverability. They will be represented as "MI for Sales" 
                    on the IP Dashboard.
                    <br></br>
                    <br></br>
                </p>
                <p className="text-center">
                    <span className="font-bold">$80</span> will be added to your subcription.
                </p>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        return(
            <>
                <Button action={closeModal} style={defaultStyles.CancelButton} label="Coming Soon"></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
        text={"IP For Sales"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default ConnectedIpsModalIpForSales;