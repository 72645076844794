import React from 'react';

const Spinner = () => {

  return (
    <div id="spinner-container">
        <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
