import React, { useEffect, useState} from 'react';
import AlertsTable from './components/DeliverabilityAlerts/DeliverabilityAlertsTable.js';
import DeliverabilityAlertsCard from './components/DeliverabilityAlerts/DeliverabilityAlertsCard.js';

const DeliverabilityAlerts = () => {
  const [cardData, setCardData] = useState([]);
  
  {/*
  useEffect(() => {
    // Fetch data from the JSON file (or API)
    fetch(
        'https://test.inboxment.com/test/alerts/cards/',
        {
          headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.json())
      .then(data => setCardData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  */}

  return (
    <div>
      <div>
        <label className='page-header'>Deliverability Alerts</label>
      </div>
      {/*
      <div className="flex justify-start">
        {cardData.map(item => (
          <DeliverabilityAlertsCard
            label={item.label}
            data={item.data}
          />
        ))}
      </div>
      <div className="mt-12">
        <AlertsTable/>
      </div>
      */}
      <div class="text-center">
        <h1 class="text-4xl font-bold text-gray-700">Under Development</h1>
        <p class="mt-2 text-gray-500">We are working hard to bring you this feature.</p>
      </div>
    </div>
  );
}

export default DeliverabilityAlerts;