import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const MailboxesModalAdd = ({reload}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [selectedIp, setSelectedIp] = useState(-1);
    const [ips, setIps] = useState({});
    const [selectedProject, setSelectedProject] = useState(-1);
    const [projects, setProjects] = useState({});
    const [selectedDomain, setSelectedDomain] = useState(-1);
    const [domains, setDomains] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { getToken } = useAuth();

    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    useEffect(() => {
        const fetchData = async () => {
            const token = await getToken();

            try {
                const response = await fetch(DJANGO_HOST + 'user_relay_server/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const ipsData = {};
                data.forEach(element => {
                    ipsData[element.relay_server_name] = element.id;
                });
                setIps(ipsData);

                if (data.length > 0) {
                    setSelectedIp(data[0].id);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const response = await fetch(DJANGO_HOST + 'projects/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const projectsData = {};
                data.forEach(element => {
                    projectsData[element.name] = element.id;
                });
                setProjects(projectsData);

                if (data.length > 0) {
                    setSelectedProject(data[0].id);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const response = await fetch(DJANGO_HOST + 'domain/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const domainsData = {};
                data.forEach(element => {
                    domainsData[element.name] = element.id;
                });
                setDomains(domainsData);
                
                if (data.length > 0) {
                    setSelectedDomain(data[0].id);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [getToken]);
    

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Add Mailbox</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <p className="text-justify">
                    Generate a Mission Inbox mailbox based on the Domains you have in our platform.
                </p>
                <br></br>
                <br></br>
                <div className="pb-4">
                    <label className="font-semibold">IP</label>
                    <Dropdown value={selectedIp} onChange={(e) => setSelectedIp(e.target.value)} label={"Select an IP"} values={ips}/>
                </div>
                <div className="pb-4">
                <label className="font-semibold">Project</label>
                    <Dropdown value={selectedProject} onChange={(e) => setSelectedProject(e.target.value)} label={"Select a Project"} values={projects}/>
                </div>
                <div className="pb-4">
                <label className="font-semibold">Domain</label>
                    <Dropdown value={selectedDomain} onChange={(e) => setSelectedDomain(e.target.value)} label={"Select a Domain"} values={domains}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">Username</label>
                    <Input name={"email"} placeholder={"Username"} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">First Name</label>
                    <Input name={"first_name"} placeholder={"First Name"} onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">Last Name</label>
                    <Input name={"last_name"} placeholder={"Last Name"} onChange={(e) => setlastName(e.target.value)}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">Password</label>
                    <Input name={"password"} placeholder={"Password"} onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        const action = async () => {

            const newErrors = [];

            if(!email){
                newErrors.push("Username cannot be empty");
            }

            if(!firstName){
                newErrors.push("First Name cannot be empty");
            }

            if(!lastName){
                newErrors.push("Last Name cannot be empty");
            }

            if(password.length < 8){
                newErrors.push("Passwords must be at least 8 characters long");
            }

            if(newErrors.length > 0){
                setErrors(newErrors);
                return;
            }

            setLoading(true);
            
            const url = DJANGO_HOST + "mailbox/";

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'relay_server' : selectedIp,
                        'project' : selectedProject,
                        'domain' : selectedDomain,
                        'email' : email,
                        'first_name' : firstName,
                        'last_name' : lastName,
                        'password' : password
                    }),
                });

                const data = await response.json();
                console.log(data);

                if (response.ok) {
                    reload();
                    closeModal();
                } else {
                    console.error('Error in response:', data);
                    // Handle error response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        return(
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Close"
                ></Button>
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Create Mailbox"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
        text={"Add Mailbox"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalAdd;