import React from "react";

const defaultLabel = () => {
  return(
    <label style={defaultStyles.Label}>
      Failed rendering this Card Title
    </label>
  );
};

const defaultBody = () => {
  return(
    <p style={defaultStyles.Body}>
      Failed rendering this Card Body
    </p>
  );
};

const defaultIcon = () => {
  return(
    <label style={defaultStyles.Icon}>
      Failed rendering this Card Icon
    </label>
  );
};

const defaultStyles = {
  Card: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    minHeight: '80px',
    backgroundColor: '#f8fafa',
    borderRadius: '10px',
    border: '1px solid #d8dbe0',
    boxSizing: 'border-box',
  }
};

const Card = ({ renderLabel=defaultLabel, renderBody=defaultBody, renderIcon=defaultIcon, style=defaultStyles }) => {
  return (
    <div div style={style.Card} className="grid mr-4 mt-4 p-4">
      <div className="mb-4">
        {renderLabel()}
      </div>
      <div className="flex justify-between">
        {renderBody()}
        <div className="flex">
          {renderIcon()}
        </div>
      </div>
    </div>
  );
}

export default Card;