import React, {useEffect, useState, useCallback} from 'react';
import DomainsTable from './components/Domains/DomainsTable.js';
import DomainsModalAdd from './components/Domains/DomainsModalAdd.js';
import DomainsModalBuy from './components/Domains/DomainsModalBuy.js';
import DomainsCard from './components/Domains/DomainsCard.js';

const Domains = () => {
  const [cardData, setCardData] = useState([]);

  {/*
  useEffect(() => {
    // Fetch data from the JSON file (or API)
    fetch(
        'https://test.inboxment.com/test/domains/cards/',
        {
          headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.json())
      .then(data => setCardData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  */}
  
  return (
    <div>
      <div>
        <label className='page-header'>Domains</label>
      </div>
      {/* 
      <div className="flex justify-start">
        {cardData.map(item => (
          <DomainsCard
            label={item.label}
            data={item.data}
            status={item.status}
          />
        ))}
      </div>
      */}
      <div className="flex w-full justify-end">
        <DomainsModalBuy/>
        <DomainsModalAdd/>
      </div>
      <div className="px-10 py-5">
        <DomainsTable/>
      </div>
    </div>
  );
}

export default Domains;