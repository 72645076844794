import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({ to, label, onClick, selected, isCollapsed, icon, comingSoon }) => {
  const location = useLocation();
  const isSelected = location.pathname === to || selected;

  return (
    <li className="relative group">
      <Link 
        to={to}
        onClick={onClick}
        className={`flex items-center p-2 text-base font-normal text-white rounded-lg hover:bg-blue-700 ${isSelected ? 'bg-blue-700' : ''}`}
        style={{ paddingLeft: isCollapsed ? '1rem' : '1.5rem' }}  // Adjust the left padding here
      >
        {icon}
        {!isCollapsed && <span className="ml-3">{label}</span>}
      </Link>
      {comingSoon && (
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-gray-500 bg-opacity-75 pointer-events-none">
          <span className="text-white">Coming Soon</span>
        </div>
      )}
    </li>
  );
};

export default MenuItem;
