import React, { useState, useEffect } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Radio from "../parents/Radio.js";
import Input from "../parents/Input.js";
import Dropdown from "../parents/Dropdown.js";
import { useAuth } from '@clerk/clerk-react';

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const DomainsModalBuy = ({ reload }) => {
    const [errors, setErrors] = useState([]);
    const [selectedIp, setSelectedIp] = useState(-1);
    const [ips, setIps] = useState({});
    const { getToken } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const token = await getToken();
            try {
                const response = await fetch(DJANGO_HOST + 'user_relay_server/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const ipsData = {};
                data.forEach(element => {
                    ipsData[element.relay_server_name] = element.id;
                });
                setIps(ipsData);

                if (data.length > 0) {
                    setSelectedIp(data[0].id);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [getToken]);
    
    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Buy Domain</label>
        );
    };

    const renderBody = () => {
        const generateBody = () => {
            return (
                <>
                    <div className="py-2 pl-8">
                        <Input
                            placeholder={"What is your primary / main domain?"}
                            name={"primary"}
                            onChange={null}
                        ></Input>
                    </div>
                    <div className="flex py-2 pl-8">
                        <div className="w-1/2">
                            <label className="text-slate-500">How many variations would you like to buy</label>
                        </div>
                        <div className="w-1/2">
                            <Input
                                placeholder={"#"}
                                name={"variants"}
                                onChange={null} //verify for only numbers
                            ></Input>
                        </div>
                    </div>
                    <div className="w-full flex justify-end pb-4">
                        <Button
                            label={"Add"}
                        ></Button>
                    </div>
                </>
            );
        };

        const specificBody = () => {
            return (
                <>
                    <div className="py-2 pl-8">
                        <Input
                            placeholder={"What domain would you like to buy?"}
                            name={"specific"}
                            onChange={null}
                        ></Input>
                    </div>
                    <div className="w-full flex justify-end pb-4 py-2">
                        <Button
                            label={"Check and Add"}
                        ></Button>
                    </div>
                </>
            );
        };

        const options = [
            { label: 'Auto Generate Domains', value: 'generate', body: generateBody },
            { label: 'Buy Specific Domain', value: 'specific', body: specificBody }
            // Add more options as needed
        ];

        const handleRadioChange = (value) => {
            console.log('Selected value:', value);
        };

        return (
            <>
                <p className="text-justify">
                    Buy your domains with Mission Inbox. You can buy domains and check availability or you can automatically generate your domain, based on popular opinion.
                </p>
                <div className="flex mt-4">
                    <div className="w-1/2 px-4 grid">
                        <Radio options={options} name="example" onChange={handleRadioChange} />
                    </div>
                    <div className="w-1/2 px-4 border-l-2 border-black">
                        <div className="pb-4">
                            <label className="py-4">IP Selection</label>
                            <Dropdown 
                                value={selectedIp} 
                                onChange={handleIPChange} 
                                label={"Select an IP"} 
                                values={ips}
                            />
                        </div>
                        <div className="py-4">
                            <label className="py-4">Domain Forwarding</label>
                            <Input
                                placeholder={"Enter website to redirect"}
                            ></Input>
                        </div>
                    </div>
                </div>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        const accept = () => {
            console.log("Example");
        };
        
        return(
            <>
                <div className="flex w-full justify-end">
                    <Button action={closeModal} style={defaultStyles.CancelButton} label="Coming Soon"></Button>
                </div>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    const handleIPChange = (event) => {
        setSelectedIp(event.target.value);
    };
    
    return (
        <Modal
        text={"Buy Domain"}
        maxWidth={"max-w-4xl"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default DomainsModalBuy;