import React from 'react';

const CopyChecker = () => {
  return (
    <div>
      <div>
        <label className='page-header'>Copy Checker</label>
      </div>
      <div class="text-center">
        <h1 class="text-4xl font-bold text-gray-700">Under Development</h1>
        <p class="mt-2 text-gray-500">We are working hard to bring you this feature.</p>
      </div>
    </div>
  );
}

export default CopyChecker;