import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner';
import { useAuth } from '@clerk/clerk-react';

const Table = ({ url, title, headers, preffixColumn, suffixColumn, renderPrefix, renderSuffix }) => {
  const [tableData, setTableData] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const token = await getToken();

      fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data && typeof data === 'object' && !Array.isArray(data)) {
          const transformedData = Object.entries(data).map(([key, value]) => ({
            key,
            value,
          }));
          setTableData(transformedData);
        } else {
          setTableData(data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
    }

    fetchData();
  }, [url, getToken]);

  if (!tableData) {
    return <Spinner/>;
  }

  if (tableData.length === 0){
    console.log(tableData);
    return <label>There is currently no elements on {title}</label>;
  }

  const extraRowClassName = tableData.length % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100';
  
  const columnCount = Object.keys(tableData[0] || {}).filter(key => key !== 'id' && key !== 'icon' && key !== 'active' && key !== 'starred').length 
                  + (preffixColumn ? 1 : 0) 
                  + (suffixColumn ? 1 : 0);

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse font-sans">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-sm leading-normal font-semibold">
              {preffixColumn && (
                <th className="py-3 px-3 text">{preffixColumn}</th>
              )}
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`py-3 px-3 text-left ${header.key === 'host' ? 'host-column' : ''} ${!header.available ? 'bg-gray-100 text-gray-500 cursor-not-allowed relative group' : ''}`}
                >
                  {header.name}
                  {!header.available && (
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-gray-500 bg-opacity-75">
                      <span className="text-white">Coming Soon</span>
                    </div>
                  )}
                </th>
              ))}
              {suffixColumn && (
                <th className="py-3 px-3 text-left">{suffixColumn}</th>
              )}
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {tableData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={rowIndex % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100'}
              >
                {preffixColumn && renderPrefix(row)}
                {Object.entries(row)
                  .filter(([key]) => key !== 'id' && key !== 'icon' && key !== 'active' && key !== 'starred') // Exclude some fields (may want to move these items into metadata)
                  .map(([key, cell], cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`py-3 px-3 text-left text-balance cell-wrap 
                      ${key === 'value' ? 'value-column' : ''}
                      ${key === 'verified' ? 'host-column' : ''}
                      ${key === 'host' ? 'host-column' : ''} 
                      ${typeof cell === 'boolean' ? (cell ? 'cell-true' : 'cell-false') : ''}`}
                    >
                      {/* Handle specific keys here */}
                      {
                      key === 'status' ? (cell ? 'Verified' : 'Not Verified') : 
                      key === 'verified' ? (cell ? 'Verified' : 'Not Verified') :
                      cell
                      }
                      
                    </td>
                  ))}
                {suffixColumn && renderSuffix(row)}
              </tr>
            ))}
            <tr className={extraRowClassName}>
              {[...Array(columnCount)].map((_, index) => (
                <td key={index} className="py-3 px-3 text-left whitespace-nowrap">
                  <span className='invisible'>A</span>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
