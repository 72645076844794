import React from 'react';

const Home = () => {
  return (
    <div>
      <h2>Welcome to the Dashboard</h2>
      <p>Please select an option from the menu to get started.</p>
    </div>
  );
};

export default Home;
