import React, { useEffect } from 'react';
import Table from '../parents/Table.js';
import ProjectsModalDisable from './ProjectsModalDisable.js';
import ProjectsModalDelete from './ProjectsModalDelete.js';
import ProjectsModalEdit from './ProjectsModalEdit.js';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ProjectsTable = ({ reload }) => {


    const url = DJANGO_HOST + 'projects/';

    const headers = [
      { name: "Project", available: true },
      { name: "Domains", available: true },
      { name: "Mailboxes", available: true },
      { name: "Sent Emails", available: true },
      { name: "Open Rate", available: false },
      { name: "Reply Rate", available: false },
      { name: "Bounce Rate", available: false },
      { name: "Spam Complaints", available: false },
      { name: "Status", available: true },
      { name: "Health", available: false }
    ];
  
    const handlePrefixAction = (option, id) => {

    };

    const handleSuffixAction = (option, id) => {

    };

    const renderPrefix = (row) => {
        return (
            <td className="py-3 px-6 flex">
                <ProjectsModalDisable isChecked={row.active}></ProjectsModalDisable>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
          <td className="py-3 px-2 flex">
                <ProjectsModalEdit name={row.name} id={row.id} reload={reload}></ProjectsModalEdit>
                <ProjectsModalDelete name={row.name} id={row.id} reload={reload}></ProjectsModalDelete>
          </td>
        );
    };

    const urlHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
  
    return (
      <Table
        url={url}
        urlHeaders={
            urlHeaders
        }
        headers={headers}
        title={"Projects"}
        preffixColumn={null}
        suffixColumn={"Actions"}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
        handlePrefixAction={handlePrefixAction}
        handleSuffixAction={handleSuffixAction}
      />
    );
  };
  
  export default ProjectsTable;