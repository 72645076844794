import React from 'react';

const AddOnFeatures = () => {
  return (
    <div>
      <h2>AddOnFeatures Details</h2>
      <p>This is the AddOnFeatures page.</p>
    </div>
  );
};

export default AddOnFeatures;