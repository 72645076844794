import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const DomainsModalAdd = ({ reload }) => {
    const [name, setName] = useState("");
    const [selectedIp, setSelectedIp] = useState(-1);
    const [ips, setIps] = useState({});
    const [selectedProject, setSelectedProject] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState({});
    const [errors, setErrors] = useState([]);
    const { getToken } = useAuth();

    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    // fetch the content of the dropdowns
    useEffect(() => {
        const fetchData = async () => {
            const token = await getToken();

            try {
                const response = await fetch(DJANGO_HOST + 'user_relay_server/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const ipsData = {};
                data.forEach(element => {
                    ipsData[element.relay_server_name] = element.id;
                });
                setIps(ipsData);

                if (data.length > 0) {
                    setSelectedIp(data[0].id);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }

            try {
                const response = await fetch(DJANGO_HOST + 'projects/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const projectsData = {};
                data.forEach(element => {
                    projectsData[element.name] = element.id;
                });
                setProjects(projectsData);

                if (data.length > 0) {
                    setSelectedProject(data[0].id);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [getToken]);
    

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Add Domain</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <p className="text-justify">
                    Add your domains to Mission Inbox. This way, you can benefit from our high-volume mailboxes with your mature domains.
                </p>
                <br></br>
                <br></br>
                <div className="pb-4">
                    <label className="font-semibold">IP</label>
                    <Dropdown value={selectedIp} onChange={(e) => setSelectedIp(e.target.value)} label={"Select an IP"} values={ips}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">Project</label>
                    <Dropdown value={selectedProject} onChange={(e) => setSelectedProject(e.target.value)} label={"Select a Project"} values={projects}/>
                </div>
                <div className="pb-4">
                    <label className="font-semibold">Domain</label>
                    <Input name={"name"} placeholder={"Name"} onChange={(e) => setName(e.target.value)}/>
                </div>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            const newErrors = [];

            if(selectedIp === -1){
                newErrors.push("Please select an IP");
            }
            if(selectedProject === -1){
                newErrors.push("Please select a Project");
            }
            if(!name){
                newErrors.push("Domain cannot be empty");
            }
            
            if(newErrors.length > 0){
                setErrors(newErrors);
                return;
            }

            setErrors([]);

            setLoading(true);

            const url = DJANGO_HOST + "domain/";

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': name,
                        'relay_server' : selectedIp,
                        'project' : selectedProject
                    }),
                });

                const data = await response.json();
                console.log(data);

                if (response.ok) {
                    reload();
                } else {
                    console.error('Error in response:', data);
                    // Handle error response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        return(
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                    ></Button>
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Add Domain"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
        text={"Add Domain"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default DomainsModalAdd;