import React from 'react';
import Card from '../parents/Card.js';


const ProjectsCard = ({label, data}) => {

    const styles = {
        Card: {
          width: '100%',
          maxWidth: '200px',
          height: 'auto',
          minHeight: '80px',
          backgroundColor: '#f8fafa',
          borderRadius: '1rem',
          border: '0.2rem solid',
          boxSizing: 'border-box',
        }
      };
    
    const renderLabel = () => {
        return (
            <label className="font-bold">{label}</label>
        );
    }

    const renderBody = () => {
        return (
            <p>{data}</p>
        );
    }

    const renderIcon = () => {
        return (<></>);
    };

    switch(label){
        case "Active Projects":
            styles.Card.borderColor = '#39d9d3';
            break;
        case "Alerts":
            styles.Card.borderColor = '#cc4633';
            break;
        default:
            styles.Card.borderColor = 'gray';
      }

    return (
        <Card
            renderLabel={renderLabel}
            renderBody={renderBody}
            renderIcon={renderIcon}
            style={styles}
        />
    );

}

export default ProjectsCard;
