import React from 'react';

const HelpCenter = () => {
  return (
    <div>
      <h2>HelpCenter Details</h2>
      <p>This is the HelpCenter page.</p>
    </div>
  );
};

export default HelpCenter;