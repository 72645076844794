import React from 'react';

const UserInfo = ({ isCollapsed }) => {
  if (isCollapsed) {
    return null;
  }

  return (
    <div className="bg-custom-background p-4 text-white">
      <div className="mb-4">
        <p>IPs: 4</p>
        <p>Projects: 3</p>
        <p>Domains: 3</p>
        <p>Mailboxes: 3</p>
      </div>
      <div className="p-0 rounded border-4 border-yellow-300 bg-custom-background relative">
        <div className="absolute -top-3 left-3 px-2 py-0.5 bg-yellow-300 text-black rounded-tl-lg rounded-br-lg">Trial</div>
        <div className="mt-6 ml-3 font-bold">Joe Doe</div>
        <div className="ml-3 text-sm">joedoe@email.abc</div>
      </div>
    </div>
  );
};

export default UserInfo;
