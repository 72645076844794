import React, { useEffect } from 'react';
import Button from '../parents/Button';

const MyAccount = () => {

  const redirect = () => {
    const url = "https://tight-redbird-46.accounts.dev/user";

    window.location.href = url;
  };

  const style = {
    Button : {
      display : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '12px 12px',
      margin: '1rem',
      border: '0',
      boxSizing: 'border-box',
      borderRadius: '6px',
      backgroundColor: '#39d9d3',
      color: '#000000',
      fontWeight: 700,
      lineHeight: '20px',
      outline: 'none',
    },
    Label : {
        color : 'red'
    }
  }

  return (
    <div className="flex justify-center">
      <div>
          <Button 
          label={<span>Go to my Account&emsp;</span>}
          style={style.Button}
          action={redirect}
          rightIcon={
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke="currentColor" 
            className="w-6 h-6">
              <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          }
          />
      </div>
    </div>
  );
};

export default MyAccount;