import React, {useState, useEffect, useCallback } from 'react';
import MailboxesTable from "./components/Mailboxes/MailboxesTable.js"
import MailboxesCard from "./components/Mailboxes/MailboxesCard.js"
import MailboxesModalAdd from './components/Mailboxes/MailboxesModalAdd.js';
import MailboxesModalGet from './components/Mailboxes/MailboxesModalBuy.js';

const Mailboxes = () => {
  const [cardData, setCardData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  const triggerReload = useCallback(() => {
      setReloadKey((prevKey) => prevKey + 1);
  }, []);

  {/*
  useEffect(() => {
    // Fetch data from the JSON file (or API)
    fetch(
        'https://test.inboxment.com/test/mailboxes/cards/',
        {
          headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.json())
      .then(data => setCardData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  */}
  
  return (
    <div key={reloadKey}>
      <label className='page-header'>Mailboxes</label>
      {/* 
      <div className="flex justify-start">
        {cardData.map(item => (
          <MailboxesCard
            label={item.label}
            data={item.data}
            status={item.status}
          />
        ))}
      </div>
      */}
      <div className="flex w-full justify-end">
        <MailboxesModalAdd reload={triggerReload}></MailboxesModalAdd>
        {/* <MailboxesModalGet></MailboxesModalGet> */}
      </div>
      <div className="px-10 py-5">
        <MailboxesTable reload={triggerReload}></MailboxesTable>
      </div>
    </div>
  );
}

export default Mailboxes;