import React from 'react';
import Table from '../parents/Table.js';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ControlCenterProjectsTable = () => {
  const url = DJANGO_HOST + 'projects/';

  const headers = [
    { name: "Project", available: true },
    { name: "Domains", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true },
    { name: "Open Rate", available: false },
    { name: "Reply Rate", available: false },
    { name: "Bounce Rate", available: false },
    { name: "Spam Complaints", available: false },
    { name: "Status", available: true },
    { name: "Health", available: false }
  ];

  const handlePrefixAction = (option, id) => {};

  const handleSuffixAction = (option, id) => {};

  const renderPrefix = (row) => {};

  const renderSuffix = (row) => {};
  
  return (
    <Table
      url={url}
      title={"Projects"}
      headers={headers}
      preffixColumn={false}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
    />
  );
};

export default ControlCenterProjectsTable;
