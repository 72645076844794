import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { SignedIn, SignedOut, SignInButton, SignOutButton, useUser } from "@clerk/clerk-react";

import MenuItem from './pages/components/MenuItem';
import TopLogo from './pages/components/TopLogo.js';
import SearchBar from './pages/components/SearchBar.js';
import UserInfo from './pages/components/UserInfo.js';

import ControlCenter from './pages/ControlCenter.js';
import DeliverabilityAlerts from './pages/DeliverabilityAlerts.js';
import ConnectedIps from './pages/ConnectedIps.js';
import Domains from './pages/Domains.js';
import Mailboxes from './pages/Mailboxes.js';
import Projects from './pages/Projects.js';
import ListCleanUps from './pages/ListsCleanUps.js';
import CopyChecker from './pages/CopyChecker.js';
import Settings from './pages/Settings.js';
import Home from './pages/Home.js';
import './index.css';

function App() {
  const { isSignedIn, user, isLoaded } = useUser();
  const [selectedItem, setSelectedItem] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!isLoaded) {
    return null;
  }

  if (!isSignedIn) {
    window.location = "https://tight-redbird-46.accounts.dev/sign-in";
  } else {
    return (
      <Router>
        <div className="flex flex-col h-screen font-poppins bg-blue-800">
          <div className="bg-custom-background flex justify-between p-3 items-center">
            <div className="flex items-center">
              <button className="p-2" onClick={toggleSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              <TopLogo />
            </div>
            <SignedIn>
              <div className="ml-4 relative w-1/3">
                <SearchBar />
              </div>
              <div className="flex">
                <button className="ml-4 px-4 py-2 bg-custom-buttons text-black rounded-md text-sm">
                  Activate Subscription
                </button>
                <Link to={"/Settings/"} onClick={() => handleMenuItemClick('')}>
                  <div className="flex items-center pt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="w-6 h-6 mr-4 ml-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  </div>
                </Link>
              </div>
            </SignedIn>
          </div>
          <div className="flex flex-1 overflow-hidden">
            <nav className={`flex flex-col ${isCollapsed ? 'w-16' : 'w-56'} bg-custom-background transition-width duration-300 ease-in-out`}>
              <div className="overflow-y-auto h-full flex flex-col">
                <SignedIn>
                  <ul className="py-4 text-white flex-1 space-y-1">
                    <MenuItem 
                      to="/ControlCenter" 
                      label="Control Center" 
                      onClick={() => handleMenuItemClick('ControlCenter')}
                      selected={selectedItem === 'ControlCenter'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                        </svg>
                      }
                    />
                    <MenuItem 
                      to="/DeliverabilityAlerts" 
                      label="Deliverability Alerts"
                      onClick={() => handleMenuItemClick('DeliverabilityAlerts')}
                      selected={selectedItem === 'DeliverabilityAlerts'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                        </svg>
                      }
                      comingSoon={true}
                    />
                    <MenuItem 
                      to="/ConnectedIps" 
                      label="Connected IPs" 
                      onClick={() => handleMenuItemClick('ConnectedIps')}
                      selected={selectedItem === 'ConnectedIps'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                        </svg>
                      }
                    />
                    <MenuItem
                      to="/Projects" 
                      label="Projects" 
                      onClick={() => handleMenuItemClick('Projects')}
                      selected={selectedItem === 'Projects'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                      }
                    />
                    <MenuItem 
                      to="/Domains" 
                      label="Domains" 
                      onClick={() => handleMenuItemClick('Domains')}
                      selected={selectedItem === 'Domains'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
                        </svg>
                      }
                    />
                    <MenuItem 
                      to="/Mailboxes" 
                      label="Mailboxes" 
                      onClick={() => handleMenuItemClick('Mailboxes')}
                      selected={selectedItem === 'Mailboxes'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                        </svg>
                      }
                    />
                    <MenuItem 
                      to="/ListCleanUps" 
                      label="List Clean Ups" 
                      onClick={() => handleMenuItemClick('ListCleanUps')}
                      selected={selectedItem === 'ListCleanUps'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                      }
                      comingSoon={true}
                    />
                    <MenuItem 
                      to="/CopyChecker" 
                      label="Copy Checker" 
                      onClick={() => handleMenuItemClick('CopyChecker')}
                      selected={selectedItem === 'CopyChecker'}
                      isCollapsed={isCollapsed}
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                        </svg>
                      }
                      comingSoon={true}
                    />
                  </ul>
                  {!isCollapsed && <UserInfo />}
                  <div className="mt-auto">
                    <ul className="text-white space-y-1 mb-4">
                      <MenuItem 
                        to="/Settings/" 
                        label="My Subscription" 
                        onClick={() => handleMenuItemClick('MySubscription')}
                        selected={selectedItem === 'MySubscription'}
                        isCollapsed={isCollapsed}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                          </svg>
                        }
                      />
                    </ul>
                    <ul className="text-white mb-4">
                      <SignOutButton>
                        <MenuItem
                          to="/Logout" 
                          label="Logout" 
                          onClick={null}
                          selected={selectedItem === 'Logout'}
                          isCollapsed={isCollapsed}
                          icon={
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                              <path d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                            </svg>
                          }
                        />
                      </SignOutButton >
                    </ul>
                  </div>
                </SignedIn>
                <SignedOut>
                  <ul className="py-4 text-white flex-1 h-full space-y-1">
                    <SignInButton>
                      <MenuItem
                        to="/"
                        label="Login"
                        onClick={null}
                        selected={selectedItem === "Login"}
                        isCollapsed={isCollapsed}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                          </svg>
                        } 
                      />
                    </SignInButton>
                  </ul>
                </SignedOut>
              </div>
            </nav>
            <main className="flex-1 overflow-y-auto p-5 bg-white rounded-lg">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ControlCenter" element={<ControlCenter />} />
                <Route path="/DeliverabilityAlerts" element={<DeliverabilityAlerts />} />
                <Route path="/ConnectedIps" element={<ConnectedIps />} />
                <Route path="/Domains" element={<Domains />} />
                <Route path="/Mailboxes" element={<Mailboxes />} />
                <Route path="/Projects" element={<Projects />} />
                <Route path="/ListCleanUps" element={<ListCleanUps />} />
                <Route path="/CopyChecker" element={<CopyChecker />} />
                <Route path="/Settings//*" element={<Settings />} />
              </Routes>
            </main>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
