import React, { useState } from 'react';

const Switch = ({ id, label, isChecked, onChange, trueAction, falseAction }) => {
  const [isSelected, setIsSelected] = useState(isChecked);

  const handleSwitchChange = (event) => {
    const selected = event.target.selected;
    setIsSelected(selected);
    onChange && onChange(selected);

    if (selected) {
      trueAction && trueAction();
    } else {
      falseAction && falseAction();
    }
  };

  const defaultStyles = {
    switchStyle : {
        position: 'relative',
        display: 'inline-block',
        width: '40px',
        height: '20px',
    },
    inputStyle : {
        opacity: 0,
        width: 0,
        height: 0,
    },
    sliderStyle : {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isSelected ? '#4CAF50' : '#F44336',
        borderRadius: '20px',
        transition: 'background-color 0.3s',
    },
    sliderBeforeStyle : {
        position: 'absolute',
        content: '""',
        height: '16px',
        width: '16px',
        left: '2px',
        bottom: '2px',
        backgroundColor: 'white',
        borderRadius: '50%',
        transition: 'transform 0.3s',
        transform: isSelected ? 'translateX(20px)' : 'translateX(0)',
    }
  }


  return (
    <label htmlFor={id} style={{ display: 'flex', alignItems: 'center' }}>
      <span style={defaultStyles.switchStyle}>
        <input
          type="checkbox"
          id={id}
          checked={isSelected}
          onChange={handleSwitchChange}
          style={defaultStyles.inputStyle}
        />
        <span style={defaultStyles.sliderStyle}>
          <span style={defaultStyles.sliderBeforeStyle}></span>
        </span>
      </span>
      {label && <span style={{ marginLeft: '8px' }}>{label}</span>}
    </label>
  );
};

export default Switch;