import React from 'react';
import Table from '../parents/Table.js';

const AlertsTable = () => {
    const url = 'https://test.inboxment.com/test/alerts/';

    const headers = [
        "Alert",
        "Type",
        "Sent",
        "Open Rate",
        "Spam Complaints",
        "Health",
        "Suggestion"
    ];
  
    const handlePrefixAction = (option, id) => {};
    
    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
        return (
            <td className="py-3 px-6 text-left whitespace-nowrap">
                {row.icon === 'warning' && (
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke-width="1.5" 
                        stroke="orange" 
                        class="w-6 h-6">
                        <path 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                    </svg>
                )}

                {row.icon === 'critical' && (
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke-width="1.5" 
                        stroke="red" 
                        class="w-6 h-6">
                        <path 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                    </svg>
                )}
            </td>
        );
    };

    const renderSuffix = (row) => {};
  
    return (
      <Table
        url={url}
        headers={headers}
        preffixColumn={true}
        suffixColumn={false}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
      />
    );
  };
  
  export default AlertsTable;