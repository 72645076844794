import React from 'react';
import Table from '../parents/Table.js';
import MailboxesModalConnect from './MailboxesModalConnect.js';
import MailboxesModalDelete from './MailboxesModalDelete.js';
import MailboxesModalDisable from './MailboxesModalDisable.js';
import MailboxesModalEdit from './MailboxesModalEdit.js';

const MailboxesTable = () => {
    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
    
    const url = DJANGO_HOST + 'mailbox/';

    const headers = [
      { name: "Mailbox", available: true },
      { name: "Password", available: true },
      { name: "First Name", available: true },
      { name: "Last Name", available: true },
      { name: "Sent Emails", available: true },
      { name: "Open Rate", available: false },
      { name: "Reply Rate", available: false },
      { name: "Bounce Rate", available: false },
      { name: "Spam Complaints", available: false },
      { name: "Health", available: false }
    ];
  
    const handlePrefixAction = (option, id) => {

    };

    const handleSuffixAction = (option, id) => {

    };

    const renderPrefix = (row) => {
        return (
            <td className="py-3 px-6 flex">
                <MailboxesModalDisable isChecked={row.active}></MailboxesModalDisable>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
          <td className="py-3 px-2 flex">
            <MailboxesModalConnect></MailboxesModalConnect>
            <MailboxesModalEdit></MailboxesModalEdit>
            <MailboxesModalDelete></MailboxesModalDelete>
          </td>
        );
    };
  
    return (
      <Table
        url={url}
        headers={headers}
        title={"Mailboxes"}
        preffixColumn={null}
        suffixColumn={"Actions"}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
        handlePrefixAction={handlePrefixAction}
        handleSuffixAction={handleSuffixAction}
      />
    );
  };
  
  export default MailboxesTable;