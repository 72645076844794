import React, { useState } from 'react';

const Radio = ({ options, name, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <>
            {options.map((option, index) => (
                <>
                    <label key={index} className="inline-flex items-center">
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={selectedValue === option.value}
                            onChange={handleChange}
                            className="radio-input"
                        />
                        <span className="ml-2">{option.label}</span>
                    </label>
                    {option.body()}
                </>
            ))}
        </>
    );
};

export default Radio;