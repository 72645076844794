import React, { useState } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";
import Input from "../parents/Input.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ConnectedIpsModalAddIp = ({ reload }) => {
    const [provider, setSelectedProvider] = useState('');
    const [name, setName] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>You want to add an IP?</label>
        );
    };

    const renderBody = () => {
        const options = {
            'Sendgrid' : 'Sendgrid', 
            'Mailgun' : 'Mailgun'

        }

        return (
            <>
                <p className="text-justify">
                    By adding an IP you are allowing Mission Inbox to connect and receive email sending analytics from it.
                </p>
                <br></br>
                <br></br>
                <label className="font-semibold">IP Provider</label>
                <div className="pb-4">
                    <Dropdown 
                    value={provider} 
                    onChange={handleProviderChange}
                    label={"Select IP Provider"} 
                    values={options}
                    />
                </div>
                <label className="font-semibold">IP Name</label>
                <div className="pb-4">
                    <Input name={"name"} placeholder={"IP Name"} onChange={(e) => setName(e.target.value)}/>
                </div>
                <label className="font-semibold">API Key</label>
                <div className="pb-4">
                    <Input name={"key"} placeholder={"API Key"} onChange={(e) => setApiKey(e.target.value)}/>
                </div>
            </>
        );
    };

    const renderOptions = (closeModal) => {

        const action = async () => {
            
            // Before trying to do any action, verify the inputs
            const newErrors = [];

            if (!provider) {
                newErrors.push('Please select a provider.');
            }
            if (!name) {
                newErrors.push('Please enter a name.');
            }
            if (!apiKey) {
                newErrors.push('Please enter an API key.');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            // In case no errors are found, clean the previous ones
            setErrors([]);

            // If the information is properly set, then start the sending
            setLoading(true);

            const url = DJANGO_HOST + "relay_servers/";

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': name,
                        'transporter_key' : apiKey,
                        'transporter_provider': provider
                    }),
                });

                const data = await response.json();

                if (response.status === 201) {
                    reload();
                    closeModal();
                } else {
                    // Handle error response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle general error response
            }

            setLoading(false);
        };
        
        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel">
                </Button>
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Yes, Connect"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    const handleProviderChange = (event) => {
        setSelectedProvider(event.target.value);
    };
    
    return (
        <Modal
        text={"Add IP"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default ConnectedIpsModalAddIp;