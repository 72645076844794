import React, { useState } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import 'tippy.js/dist/tippy.css';

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ProjectsModalEdit = ({ name, id, reload }) => {
    const [newName, setNewName] = useState("");
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();
    
    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Edit - {name}</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <div className="invisible">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </div>
                <p className="text-justify">
                    Change the name of your project.
                </p>
                <br></br>
                <br></br>
                <div className="pb-4">
                    <Input name={"name"} placeholder={"Name"} onChange={(e) => setNewName(e.target.value)}/>
                </div>
            </>
        );

    };

    const renderOptions = (closeModal) => {
        const action = async () => {

            // Before trying to do any action, verify the inputs
            const newErrors = [];

            if (!newName) {
                newErrors.push('Name cannot be empty');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            // In case no errors are found, clean the previous ones
            setErrors([]);

            // If the information is properly set, then start the sending
            setLoading(true);
            
            const url = DJANGO_HOST + "projects/" + id +"/";

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body : JSON.stringify({
                        'name' : newName
                    })
                });

                if (response.status === 200) {
                    reload();
                    closeModal();
                } else {
                    // Handle error response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        return(
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                ></Button>
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Update"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
        icon={"edit"}
        tooltip={"Edit"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default ProjectsModalEdit;