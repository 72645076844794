import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MyAccount from './components/Settings/MyAccount.js';
import Preferences from './components/Settings/Preferences.js';
import AddOnFeatures from './components/Settings/AddOnFeatures.js';
import Billing from './components/Settings/Billing.js';
import Integrations from './components/Settings/Integrations.js';
import HelpCenter from './components/Settings/HelpCenter.js';
import MenuItem from './components/MenuItem';

const Settings = () => {
  const [selectedSubItem, setSubSelectedItem] = useState('');

  const handleSubMenuItemClick = (item) => {
    setSubSelectedItem(item);
  };

  return (
    <div className="flex h-full">
      <div className="w-1/4 p-4">
        <ul className="py-4 text-black flex-1 h-full">
          <MenuItem 
          to="MyAccount" 
          label="My Account" 
          onClick={() => handleSubMenuItemClick('MyAccount')}
          subSelected={selectedSubItem === 'MyAccount'}
          icon={<div className='mr-4'></div>} 
          />
          <MenuItem 
          to="Preferences" 
          label="Preferences" 
          onClick={() => handleSubMenuItemClick('Preferences')} 
          subSelected={selectedSubItem === 'Preferences'} 
          icon={<div className='mr-4'></div>} 
          />
          <MenuItem 
          to="AddOnFeatures" 
          label="Add-on Features" 
          onClick={() => handleSubMenuItemClick('AddOnFeatures')} 
          subSelected={selectedSubItem === 'AddOnFeatures'} 
          icon={<div className='mr-4'></div>} 
          />
          <MenuItem 
          to="Billing" 
          label="Billing" 
          onClick={() => handleSubMenuItemClick('Billing')} 
          subSelected={selectedSubItem === 'Billing'} 
          icon={<div className='mr-4'></div>} />
          <MenuItem 
          to="Integrations" 
          label="Integrations" 
          onClick={() => handleSubMenuItemClick('Integrations')} 
          subSelected={selectedSubItem === 'Integrations'} 
          icon={<div className='mr-4'></div>} 
          />
          <MenuItem 
          to="HelpCenter" 
          label="Help Center" 
          onClick={() => handleSubMenuItemClick('HelpCenter')} 
          subSelected={selectedSubItem === 'HelpCenter'} 
          icon={<div className='mr-4'></div>} 
          />
        </ul>
      </div>
      <div className="w-3/4 p-4">
        <Routes>
          <Route path="MyAccount" element={<MyAccount />} />
          <Route path="Preferences" element={<Preferences />} />
          <Route path="AddOnFeatures" element={<AddOnFeatures />} />
          <Route path="Billing" element={<Billing />} />
          <Route path="Integrations" element={<Integrations />} />
          <Route path="HelpCenter" element={<HelpCenter />} />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;