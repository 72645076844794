import React, { useState } from 'react';

const Checkbox = ({ id, label }) => {
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const defaultStyles = {
      Container : {
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
      },
      Input : {
          display: 'none',
      },
      Label : {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
      },
      Icon : {
          width: '20px',
          height: '20px',
          borderRadius: '4px',
          border: isChecked ? 'none' : '2px solid #ccc',
          marginRight: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isChecked ? 'blue' : 'transparent',
      },
      Selected : {
          content: '"✓"',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
      }
    };


    return (
      <div style={defaultStyles.Container}>
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={defaultStyles.Input}
        />
        <label htmlFor={id} style={defaultStyles.Label}>
          <span style={defaultStyles.Icon}>
            {isChecked && <span style={defaultStyles.Selected}>✓</span>}
          </span>
          {label}
        </label>
      </div>
    );
  };

export default Checkbox;