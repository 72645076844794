import React from 'react';

const Preferences = () => {
  return (
    <div>
      <h2>Preferences Details</h2>
      <p>This is the My Preferences page.</p>
    </div>
  );
};

export default Preferences;